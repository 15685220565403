import http from '@/http';
import globalHelper from "@/helpers/global.helper";
import { experian, opportunity, donate } from './sub-modules';

export default {
    modules: {
        experian,
        opportunity,
        donate
    },
    state: {
        model: {
            first_name: '',
            last_name: '',
            address1: '',
            suburb: '',
            state: '',
            postcode: '',
            country: '',
            email: '',
            phone: '',
            amount: 400,
            frequency: 'O',
            payment_type: 'CC',
            payment_method_id: null,
            receipt_name: '',
            sf_account_id: '',
            donation_behalf: '',
            details_type: 'personal',
            email_opt_out_news: true,
            opt_in: null,
            source: 'Web Donation Form',
            invalidCard: true,
            recaptcha: null
        },
        donatedModel: {},
        paymentModel: {
            stripeErr: '',
            cardValidate: false,
            cardNumber: null,
            auBankAccount: null,
        },
        processLoading: false
    },
    getters: {
        model: state => state.model,
        donatedModel: state => state.donatedModel,
        paymentModel: state => state.paymentModel,
        processLoading: state => state.processLoading,
    },
    mutations: {
        setLoading(state, payload) {
            state.processLoading = payload;
        },
        updateModel(state, payload) {
            state.processLoading = false;
            state.model = globalHelper.updatePropertyObj(state.model, payload);
        },
        updateDonateModel(state, payload) {
            state.processLoading = false;
            state.donatedModel = payload;
        }, 
        updatePaymentModel(state, payload) {
            state.paymentModel = globalHelper.updatePropertyObj(state.paymentModel, payload);
        },
        resetModel(state, payload) {
            state.donatedModel = {};
            state.paymentModel = globalHelper.resetObjValue(state.paymentModel, {
                cardValidate: false,
                cardNumber: null,
                auBankAccount: null,
            });
            state.processLoading = false;
            state.model = globalHelper.resetObjValue(state.model, {
                amount: 400,
                frequency: 'O',
                payment_type: 'CC',
                payment_method_id: null,
                details_type: 'personal',
                source: 'Web Donation Form'
            });
        },
        setUpdateCardModel(state, payload) {
            let payment_type = 'CC';
            if (payload) {
                if (payload.Lightfm_Payment_Method__c === 'Direct Debit') {
                    payment_type = 'DD';
                }
                state.model = {
                    amount: payload.Lightfm_Charge_Amount__c || 0,
                    frequency: payload.Lightfm_Payment_Frequency__c || null,
                    receipt_name: payload.Lightfm_Receipt_Name__c || '',
                    payment_method_id: null,
                    payment_type: payment_type,
                    stripe_customer_id: payload.Lightfm_Tokenization__c,
                    email: payload.email,
                    invalidCard: false,
                    donation_id: payload.donation_id
                }
            } else {
                state.model.invalidCard = true;
            }
        }
    },
    actions: {
        createPaymentIntent({ commit }, data) {
            return new Promise((res, rej) => {
                http('stripe').post(`paymentmethods/payment_intent`, data).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        createStripeSetupIndent({ commit }, data) {
            return new Promise((res, rej) => {
                http('stripe').post('paymentmethods/setup_intent', data)
                    .then(response => res(response.data))
                    .catch((error) => rej(error));
            });
        },
        createSubscription({ commit }, data) {
            return new Promise((res, rej) => {
                http('stripe').post(`subscriptions`, data).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        donatePaymentAmount({ commit }, data) {
            commit("setLoading", true);
            return new Promise((res, rej) => {
                http('donate').post('donates', data).then(response => {
                    commit("toggleAppLoading");
                    const resData = response.data.data || null;
                    if (resData && resData.status !== 0) {
                        commit("snackbar/SHOW_MESSAGE", {
                            text: "Donation Processed Successfully",
                            color: "success",
                        });
                        commit("updateDonateModel", resData);
                    } else {
                        commit("snackbar/SHOW_MESSAGE", {
                            text: resData && resData.failed_reason ? resData.failed_reason : 'Error!',
                            color: "error",
                        });
                    }
                    commit("setLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setLoading", false);
                    commit("toggleAppLoading");
                    rej(error)
                });
            });
        },
        storyFormSubmit ({ commit }, data) {
            commit("setLoading", true);
            return new Promise((res, rej) => {
                http('donate').post('stories', data).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Story Saved Successfully",
                        color: "success",
                    });
                    commit("updateDonateModel", {});
                    res(response);
                }).catch((error) => {
                    commit("setLoading", false);
                    rej(error)
                });
            });
        }
    },
};
